import TrumpetIcon from '@/images/h5-common/trumpet.webp';
import { Word } from '../../typings/directory_word_model';
import { Checkbox, Radio } from 'antd-mobile';
import { EVoiceType } from '.';

interface IProps {
  data: Word;
  value: EVoiceType;
  onChange: (val: any) => void;
}

function VoiceCheck({ data, onChange, value }: IProps) {
  return (
    <div className='flex justify-center items-center mt-[6px]'>
      <div
        className='flex justify-center items-center px-[8px] py-[6px] bg-[#FCF0C8] mr-[8px] rounded-lg'
        onClick={() => onChange(EVoiceType.UK)}
      >
        {value === EVoiceType.UK ? (
          <div className='mr-[4px]'>
            <Checkbox
              style={{
                '--icon-size': '14px',
                '--font-size': '12px',
              }}
              checked
            />
          </div>
        ) : null}
        <span className='text-[12px]'>英</span>
        <span className='text-[10px]'>{`[${data?.ukPronunciation}]`}</span>
        <img className='w-[16px] inline ml-[4px]' src={TrumpetIcon} alt='' />
      </div>
      <div
        className='flex justify-center items-center px-[8px] py-[6px] bg-[#FCF0C8] rounded-lg'
        onClick={() => onChange(EVoiceType.US)}
      >
        {value === EVoiceType.US ? (
          <div className='mr-[4px]'>
            <Checkbox
              style={{
                '--icon-size': '14px',
                '--font-size': '12px',
              }}
              checked
            />
          </div>
        ) : null}
        <span className='text-[12px]'>美</span>
        <span className='text-[10px]'>{`[${data?.usPronunciation}]`}</span>
        <img className='w-[16px] inline ml-[4px]' src={TrumpetIcon} alt='' />
      </div>
    </div>
  );
}

export default VoiceCheck;
