import {useContext, useEffect, useRef, useState} from 'react';
import BottomBtn from './BottomBtn';
import TrumpetIcon from '@/images/h5-common/trumpet.webp';
import Header from './Header';
import { getRecordedShareData } from '@/restful/recorded_share';
import { queryURLParams } from '@/utils/jsUtils';
import { ErrorBlock, Loading, Swiper, SwiperRef, Toast } from 'antd-mobile';
import WeChatMask from '@/components/h5/WeChatMask';
import CommonHeader from '@/components/h5/Header';
import PlayIcon from '@/images/h5-common/play_btn.webp';
import PauseIcon from '@/images/h5-common/pause.webp';
import ShareIcon from '@/images/h5-common/icon_share.webp';

import MyAudioPlayer, {
  AudioPlayerHandle,
} from '@/components/player/MyAudioPlayer';
import {
  AudioRecord,
  MediaItem,
  PlayDataSource,
} from '@/typings/play_data_source';
import DowloadAppBanner from '@/components/h5/DowloadAppBanner';
import ScoreStars from '@/components/picturebook/ScoreStars';
import { copyToClipboard } from '@/utils/common';
import VoiceCheck from './VoiceCheck';
import { DirectoryWordModel, Word } from '@/typings/directory_word_model';
import {UserContext} from "@/index";
import {isWechat} from "@/utils/EnvUtils";
import {JsBridge} from "@/utils/JsBridge";

export enum EVoiceType {
  UK = 'UK',
  US = 'US',
}

/**
 * @description 分享绘本录制的音频
 */
function ShareWord() {
  const urlParamObj = queryURLParams(window.location.href);
  const [directoryWordModel, setDirectoryWordModel] =
    useState<DirectoryWordModel>();
  const [playDataSourceList, setPlayDataSourceList] =
    useState<PlayDataSource[]>();
  const [playDataSourceRecordList, setPlayDataSourceRecordList] =
    useState<PlayDataSource[]>();
  const [loadingSate, setLoadingSate] = useState(0); //0加载中 1加载成功 2加载失败
  const rootSwiperRef = useRef<SwiperRef | null>(null);
  const [isShowWXShare, setIsShowWXShare] = useState(false); // 微信分享弹层
  const [playRecord, setPlayRecord] = useState<boolean>(true); // 是否只听原声
  const [autoPlayNext, setAutoPlayNext] = useState<boolean>(true); // 是否自动翻页播放
  const [recordPlayIndex, setRecordPlayIndex] = useState<number>(); // 当前用户音频播放位置
  const [isPlaying, setIsPlaying] = useState(false);
  const [curPageIndex, setCurPageIndex] = useState(0); // 整体绘本的位置
  const audioPlayerRef = useRef<AudioPlayerHandle>(null);
  const [hasPlay, setHasPlay] = useState(false);
  const [voiceType, setVoiceType] = useState<EVoiceType>(EVoiceType.UK);
  const user = useContext(UserContext);

  function onShareUrl() {
    if (isWechat()) {
      setIsShowWXShare(true)
    } else {
      JsBridge.shareUrl(
          JSON.stringify({
            title: `${user?.nickName || directoryWordModel?.userInviter?.nickName || ''}录音作品`,
            text: `我正在读《${directoryWordModel?.title || "奇鹿绘本"}》，期待你的点赞\u{2764}\u{FE0F}`,
            url: window.location.href,
            thumbImg:`${directoryWordModel?.imageUrl??""}`
          })
      )
    }
  }

  useEffect(() => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.setPlayRecord(playRecord);
    }
  }, [playRecord]);

  useEffect(() => {
    if (
      audioPlayerRef.current &&
      audioPlayerRef.current.getPlayData().playlist === playDataSourceList
    ) {
      audioPlayerRef.current.setAutoPlayNext(autoPlayNext);
    }
  }, [autoPlayNext, playDataSourceList]);

  useEffect(() => {
    if (urlParamObj?.title) {
      document.title = urlParamObj?.title;
    }

    if (urlParamObj?.r) {
      // setIsDataChange(false);
      setLoadingSate(0);
      getRecordedShareData<DirectoryWordModel>({ r: urlParamObj?.r }).then(
        (res) => {
          console.log(res);
          if (res.success) {
            let tDirectoryWordModel = res.data;

            let tPlayDataSourceList: PlayDataSource[] = [];
            let tPlayDataSourceRecordList: PlayDataSource[] = [];

            tDirectoryWordModel?.words?.forEach((item: Word, index) => {
              let audioRecords: AudioRecord[] = [];
              let mediaItems: MediaItem[] = [];
              if (item.audioRecord) {
                item.audioRecord.isSentence = false;
                item.audioRecord.directoryIndex = index;
                item.audioRecord.en = item?.wordEn;
                item.audioRecord.cn = item?.wordCn;
                audioRecords.push(item.audioRecord);
              }
              mediaItems.push({
                mediaUrl: item.mediaUrl,
                directoryIndex: index,
                isSentence: false,
              });
              let playDataSource: PlayDataSource = {
                audioRecords: audioRecords,
                mediaList: mediaItems,
              };
              tPlayDataSourceList.push(playDataSource);
              if (audioRecords.length > 0) {
                audioRecords.forEach((value, index) => {
                  tPlayDataSourceRecordList.push({
                    audioRecords: [value],
                    mediaList: mediaItems,
                  });
                });
              }
            });
            if (tDirectoryWordModel?.words) {
              tDirectoryWordModel.words.unshift(tDirectoryWordModel.words[0]); //插入开始页
              tDirectoryWordModel.words.push(tDirectoryWordModel.words[0]); //插入结尾页
            }
            setDirectoryWordModel(tDirectoryWordModel);

            setPlayDataSourceList(tPlayDataSourceList);
            setPlayDataSourceRecordList(tPlayDataSourceRecordList);

            setLoadingSate(1);
          } else {
            setLoadingSate(2);
            Toast.show({
              content: res.msg,
            });
          }
        }
      );
    }
  }, []);

  function isLastItem() {
    return curPageIndex + 1 === directoryWordModel?.words?.length;
  }

  function handlePlayCurVoice(mediaUrl: string) {
    // 切换后，播放音频
    if (audioPlayerRef.current) {
      audioPlayerRef.current.setDataSource({
        playlist: [
          {
            mediaList: [
              {
                mediaUrl,
                directoryIndex: 0,
                isSentence: false,
              },
            ],
            audioRecords: [],
          },
        ],
        index: 0,
        sentenceIndex: 0,
        playRecord: true,
        autoPlay: true,
        autoPlayNext: false,
      });
    }
  }

  function onChangeVoiceType(val: EVoiceType, item: Word) {
    setVoiceType(val);
    // 切换后，播放音频
    if (audioPlayerRef.current) {
      audioPlayerRef.current.setDataSource({
        playlist: [
          {
            mediaList: [
              {
                mediaUrl:
                  val === EVoiceType.UK ? item.ukMediaUrl : item.usMediaUrl,
                directoryIndex: 0,
                isSentence: false,
              },
            ],
            audioRecords: [],
          },
        ],
        index: 0,
        sentenceIndex: 0,
        playRecord: true,
        autoPlay: true,
        autoPlayNext: false,
      });
    }
  }

  useEffect(() => {
    rootSwiperRef.current?.swipeTo(curPageIndex);
  }, [curPageIndex]);

  function getInviter() {
    return (
      <div className='flex flex-col pt-[15px]'>
        <div className='flex flex-row items-center'>
          <img
            className='w-[40px] h-[40px] rounded-full object-cover'
            src={directoryWordModel?.userInviter?.avatar}
            alt=''
          />
          <div className='ml-[10px] text-[14px] font-medium'>
            {directoryWordModel?.userInviter?.nickName}
            邀请你免费领奇鹿英语会员资格，一起学英语
          </div>
        </div>
        <div
          onClick={() =>
            copyToClipboard(
              `【免费领取英语学习神器】点击【https://deerbook.cn】下载安装后输入邀请码==>${directoryWordModel?.userInviter?.inviterCode}<==即可免费领取奇鹿Vip会员，快来领取吧！`
            )
          }
          className='flex flex-row bg-[#F5F5F5] rounded-[13px] pl-[14px] pt-[14px] pr-[27px] pb-[14px] mt-[10px] items-center'
        >
          <div className='flex-1 text-[#666666] text-[14px] font-normal'>
            邀请码：{directoryWordModel?.userInviter?.inviterCode}
          </div>
          <div className='text-[#AB885C] text-[14px] font-semibold'>复制</div>
        </div>
      </div>
    );
  }

  if (loadingSate === 0) {
    return (
      <div className='w-full h-[100vh] flex items-center justify-center'>
        <div className='fixed top-0 left-0 right-0'>
          <DowloadAppBanner alignBottom={false} />
        </div>
        <Loading className='flex flex-col items-center justify-center' />
      </div>
    );
  } else if (loadingSate === 2) {
    return (
      <div className='w-full h-[100vh] flex items-center justify-center'>
        <div className='fixed top-0 left-0 right-0'>
          <DowloadAppBanner alignBottom={false} />
        </div>
        <ErrorBlock
          status='default'
          className='flex flex-col items-center justify-center'
        />
      </div>
    );
  }

  return (
    <div className='flex items-center justify-center'>
      <div className='max-w-[800px] w-full'>
        <MyAudioPlayer
          ref={audioPlayerRef}
          playNextInterval={500}
          onPlayPause={(isPlay) => {
            setHasPlay(true);
            setIsPlaying(isPlay);
          }}
          onPlayNext={(index) => {
            if (
              audioPlayerRef.current?.getPlayData().playlist ===
              playDataSourceList
            ) {
              setCurPageIndex(index + 1);
            }
          }}
          onListPlayEnd={(index) => {
            if (
              directoryWordModel &&
              audioPlayerRef.current?.getPlayData().playlist ===
                playDataSourceList
            ) {
              setCurPageIndex(directoryWordModel?.words?.length - 1);
            }
          }}
        />
        <Swiper
          indicator={() => null}
          defaultIndex={curPageIndex}
          //如果没有播放过，第一页禁止左滑，这样能保证，用户点击一次物理播放，确保后续可以通过程序控制播放
          allowTouchMove={curPageIndex === 0 && !hasPlay ? false : true}
          onIndexChange={(index) => {
            setCurPageIndex(index);
            if (!audioPlayerRef.current) {
              return;
            }
            if (
              playDataSourceList &&
              index - 1 >= 0 &&
              index - 1 < playDataSourceList!.length
            ) {
              if (
                audioPlayerRef.current.getPlayData().playlist ===
                playDataSourceList
              ) {
                audioPlayerRef.current.playIndex(index - 1);
              } else {
                audioPlayerRef.current.setDataSource({
                  playlist: playDataSourceList,
                  index: index - 1,
                  sentenceIndex: 0,
                  playRecord: playRecord,
                  autoPlay: true,
                  autoPlayNext: autoPlayNext,
                });
              }
            } else {
              audioPlayerRef.current?.pause();
              audioPlayerRef.current.setDataSource({
                playlist: [],
                index: 0,
                sentenceIndex: 0,
                playRecord: true,
                autoPlay: false,
                autoPlayNext: true,
              });
            }
          }}
          ref={rootSwiperRef}
        >
          {directoryWordModel?.words?.map((item: Word, index: number) => {
            if (index === 0) {
              return (
                <Swiper.Item key={'first'}>
                  <div className='h-[100vh]'>
                    <div className='w-full fixed top-0'>
                      {/* <Header title={urlParamObj?.title} /> */}
                      {urlParamObj?.isNeedBackWeb !== '1' ? (
                        <DowloadAppBanner alignBottom={false} />
                      ) : (
                        <CommonHeader
                          onClickBack={() => window.history.back()}
                          title={urlParamObj?.title || `${user?.nickName || directoryWordModel?.userInviter?.nickName ||''}录音作品`}
                          rightBtn={
                            <div className=''>
                              <img
                                className='w-[40px] h-[40px]'
                                src={ShareIcon}
                                onClick={onShareUrl}
                                alt=''
                              />
                            </div>
                          }
                        />
                      )}
                    </div>
                    <div className='w-full h-[80px]'></div>
                    <div className='pt-[24px] h-[calc(100%_-_104px)]'>
                      <div className='flex items-center px-[16px] mb-[24px]'>
                        <img
                          className='w-[100px] h-[120px] rounded-[12px] mr-[24px]'
                          src={directoryWordModel?.imageUrl}
                          alt=''
                        />
                        <div className='mb-[24px]'>
                          <div className='text-[#333333] text-[18px] font-semibold mb-[10px]'>
                            {directoryWordModel?.title}
                          </div>
                          <div className='flex mb-[14px]'>
                            {directoryWordModel?.tags?.map(
                              (item: string, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className='px-[8px] py-[2px] text-[12px] text-[#999999] bg-[#E6E6E6] rounded-full mr-[10px]'
                                  >
                                    {item}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className='line-clamp-2 w-full text-[14px]'>
                            {/* {directoryBookModel?.intro} */}
                          </div>
                        </div>
                      </div>
                      <div className='w-full px-[16px] overflow-y-auto pb-[120px] h-[calc(100%_-_120px)]'>
                        {playDataSourceRecordList?.map(
                          (item: PlayDataSource, index) => (
                            <div
                              key={index}
                              // onClick={() => playUserAudio(index)}
                              onClick={() => {
                                setRecordPlayIndex(index);
                                if (audioPlayerRef.current) {
                                  audioPlayerRef.current.setDataSource({
                                    playlist: [
                                      playDataSourceRecordList![index],
                                    ],
                                    index: 0,
                                    sentenceIndex: 0,
                                    playRecord: true,
                                    autoPlay: true,
                                    autoPlayNext: false,
                                  });
                                }
                              }}
                              className={`flex flex-row px-[16px] py-[18px] mb-[14px] rounded-[14px] ${
                                index === recordPlayIndex
                                  ? 'bg-[#99d7f1]'
                                  : 'bg-[#E7F8FF]'
                              } text-[16px] font-semibold`}
                            >
                              <div className='flex-1'>
                                <span>{item.audioRecords?.[0]?.en}</span>
                                <img
                                  className='w-[18px] h-[14px] inline ml-[4px]'
                                  src={TrumpetIcon}
                                  alt=''
                                />
                              </div>
                              <ScoreStars
                                score={item?.audioRecords?.[0]?.suggestedScore}
                                className='justify-end'
                              />
                            </div>
                          )
                        )}
                        {getInviter()}
                      </div>
                    </div>
                    <BottomBtn
                      onClick={() => {
                        rootSwiperRef.current?.swipeTo(1);
                      }}
                      title={urlParamObj?.title}
                    />
                  </div>
                </Swiper.Item>
              );
            } else if (index + 1 === directoryWordModel?.words.length) {
              return (
                <Swiper.Item key={'last'}>
                  <div className='pt-[60px]'>
                    <div className='relative mx-[24px] h-[100px] flex items-end'>
                      <img
                        className='w-[78px] h-[84px] rounded-[8px] absolute left-0 bottom-0 z-10'
                        src={directoryWordModel?.imageUrl}
                        alt=''
                      />
                      <div className='w-[78px] h-[90px] rounded-[8px] absolute left-[6px] bottom-0 bg-[#D3EBEB]'></div>
                      <div className='w-full border-4 border-[#EEF9FA] border-solid rounded-[18px] h-[74px] flex items-center justify-center'>
                        <div className='w-[90px]'></div>
                        <div className='text-[18px] text-[#1E1C1C]'>{`${
                          urlParamObj?.title || ''
                        }欣赏结束`}</div>
                      </div>
                    </div>

                    <div className='pl-[15px] pr-[15px] pt-[25px]'>
                      {getInviter()}
                    </div>

                    <div className='flex flex-row mt-[40px] items-center justify-center w-full space-x-[20px]'>
                      <div
                        onClick={() => rootSwiperRef.current?.swipeTo(0)}
                        className='w-[148px] py-[12px] px-[16px] rounded-full flex items-center justify-center bg-[#40D8F4] text-[#FFFFFF] text-[16px] shadow-md'
                      >
                        返回首页
                      </div>
                      <div
                        onClick={onShareUrl}
                        className='w-[148px] py-[12px] px-[16px] rounded-full flex items-center justify-center bg-[#FFAA1B] text-[#FFFFFF] text-[16px] shadow-md'
                      >
                        分享
                      </div>
                    </div>
                  </div>
                </Swiper.Item>
              );
            }

            return (
              <Swiper.Item key={item?.id}>
                <div className='flex flex-col h-screen pb-[80px] overflow-y-auto'>
                  <div>
                    <div className='mt-[80px]'>
                      <div
                        className='flex justify-center items-baseline text-5xl font-semibold'
                        onClick={() =>
                          handlePlayCurVoice(
                            voiceType === EVoiceType.UK
                              ? item?.ukMediaUrl
                              : item?.usMediaUrl
                          )
                        }
                      >
                        {item?.wordEn}
                        <img
                          className='w-[34px] inline ml-[4px]'
                          src={TrumpetIcon}
                          alt=''
                        />
                      </div>
                      <div className='flex justify-center text-[#666666] my-[8px]'>
                        {voiceType === EVoiceType.UK
                          ? `[${item?.ukPronunciation}]`
                          : `[${item?.usPronunciation}]`}
                      </div>
                      <VoiceCheck
                        data={item}
                        onChange={(val) => onChangeVoiceType(val, item)}
                        value={voiceType}
                      />
                    </div>
                  </div>
                  <div className='flex items-center text-[11px] ml-[20px] mt-[10px]'>
                    <div className='px-[10px] py-[4px] rounded-full bg-[#D2F7FF] mr-[10px]'>
                      中文
                    </div>
                    <div className='text-[#666666] font-medium'>
                      {item?.wordCn}
                    </div>
                  </div>
                  <div className='w-full flex justify-center items-center'>
                    <img
                      src={item?.imageUrl}
                      alt=''
                      className='object-contain object-center h-[180px] mt-[16px]'
                    />
                  </div>
                  <div
                    className='flex items-center text-[11px] ml-[20px] mt-[10px]'
                    onClick={() =>
                      handlePlayCurVoice(
                        voiceType === EVoiceType.UK
                          ? item?.ukExampleMediaUrl
                          : item?.usExampleMediaUrl
                      )
                    }
                  >
                    <div className='px-[10px] py-[4px] rounded-full bg-[#D2F7FF] mr-[10px]'>
                      例句
                    </div>
                    <div className='text-[#666666] font-medium'>
                      {item?.example}
                    </div>
                    <img
                      className='w-[16px] inline ml-[4px]'
                      src={TrumpetIcon}
                      alt=''
                    />
                  </div>
                  <div className='flex items-center text-[11px] ml-[20px] mt-[10px]'>
                    <div className='px-[10px] py-[4px] rounded-full bg-[#D2F7FF] mr-[10px]'>
                      释义
                    </div>
                    <div className='text-[#666666] font-medium'>
                      {item?.exampleCn}
                    </div>
                  </div>
                  <div className='flex w-full p-[10px] justify-end'>
                    <div className='flex flex-col items-center justify-start'>
                      <img
                        className='w-[60px] h-[60px]'
                        src={isPlaying ? PauseIcon : PlayIcon}
                        alt=''
                        onClick={() => {
                          audioPlayerRef.current?.playPause();
                        }}
                      />
                      <span className='text-[14px] mt-[8px]'>
                        {index}/{directoryWordModel.words.length - 2}
                      </span>
                    </div>
                  </div>
                </div>
              </Swiper.Item>
            );
          })}
        </Swiper>

        <div
          className={`fixed top-0 w-full max-w-[800px] ${
            curPageIndex > 0 ? '' : 'hidden'
          }`}
        >
          <Header
            isHiddenBox={isLastItem() ? true : false}
            title={isLastItem() ? urlParamObj?.title : ''}
            setPlayRecord={setPlayRecord}
            autoPlayNext={autoPlayNext}
            setAutoPlayNext={setAutoPlayNext}
            showShare={onShareUrl}
          />
        </div>

        {curPageIndex > 0 ? <DowloadAppBanner /> : null}

        <WeChatMask
          isVisible={isShowWXShare}
          closeMask={() => setIsShowWXShare(false)}
          maskType={1}
        />
      </div>
    </div>
  );
}

export default ShareWord;
