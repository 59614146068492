import React, { useEffect, useState } from 'react'
import Header from '../../components/h5/Header'
import {SafeArea, Toast} from 'antd-mobile'
import { JsBridge, messageMethodIsSupport } from '../../utils/JsBridge'
import {isApp, isWechat} from '../../utils/EnvUtils'
import IconShare from '@/images/h5-common/icon_share.webp'
import IconBack from '@/images/h5-common/icon_back.webp'
import ExpandSpaceSuccess from '@/images/space_upgrade/expand_space_success.webp'
import { getWebBaseHost } from '../../restful/request'
import WeChatMask from '../../components/h5/WeChatMask'
import DowloadAppBanner from '../../components/h5/DowloadAppBanner'
import { useNavigate } from 'react-router-dom'
import {vipGetFreeSpace} from "../../restful/space_api";

const ExpandSpace = () => {
  const navigate = useNavigate()
  const [showWeixinMask, setShowWeixinMask] = useState<boolean>(false)
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    // if (JsBridge.isInApp()) {
    //   const appVersionMatch = navigator.userAgent.match(/version_([\d\\.]+)/),
    //     appVersion = appVersionMatch ? appVersionMatch[1] : '',
    //     lastestVersion =
    //       window.location.host === 'testvq.deerbook.cn'
    //         ? '99.0.1.100'
    //         : '3.12.4'
    //   if (compareVersion(appVersion, lastestVersion)) {
    //     navigate('/form_teacher', { replace: true })
    //   }
    // }

    document.title = '新年扩容礼遇活动'
    JsBridge.hideClientNavbar(
      JSON.stringify({ visible: false, statusBarColor: '#00FFFFFF' })
    )
  }, [])

  function onClose() {
    // window.history.go(-1);
    JsBridge.closeWebview(JSON.stringify({ sourceFrom: 'h5' }))
  }

  function onShareUrl() {
    if (isWechat()) {
      setShowWeixinMask(true)
    } else {
      JsBridge.shareUrl(
        JSON.stringify({
          title: '新年扩容礼遇活动',
          text: '奇鹿App限时活动',
          url: `${getWebBaseHost()}/expand_space`,
          // thumbImg:`${directoryBasicModel?.imageUrl??""}`
        })
      )
    }
  }

  function renderHeaderLeftBtn() {
    return (
      <img
        onClick={onClose}
        src={IconBack}
        alt=''
        className={`w-[36px] h-[36px] ${JsBridge.isInApp() ? '' : 'invisible'}`}
      />
    )
  }

  function renderHeaderRightBtn() {
    return (
      <img
        onClick={onShareUrl}
        src={IconShare}
        alt=''
        className='w-[36px] h-[36px]'
      />
    )
  }

  function expandSpace(){

    if(!JsBridge.isInApp()){
      window.location.href = 'https://deerbook.cn';
      return;
    }

    vipGetFreeSpace().then((res) => {
      // Toast.show({
      //   content: res.msg,
      // });
      if (res.success) {
        // console.log('getDirectoryBasic data:', res.data);
        // Toast.show({
        //   content: res.msg,
        // });
        setIsPopupVisible(true);
      } else {
        Toast.show({
          content: res.msg,
        });
      }
    });
  }

  return (
      <div className='flex items-center justify-center'>
        <div className={`relative max-w-[500px] w-full overflow-y-auto pb-[${JsBridge.isInApp()?'0':'80'}px]`}>
          <Header
              title=' '
              // headerBgColor="#FDD648"
              // className="bg-[#FDD648] text-white"
              leftBtn={renderHeaderLeftBtn()}
              rightBtn={renderHeaderRightBtn()}
              className="absolute top-0 left-0 right-0 z-10"
          />
          <img
              onClick={() => expandSpace()}
              alt=''
              src="https://cdn4.deerbook.cn/img/activity/expand_space.webp"
              className='w-full object-cover'
          />

          {isPopupVisible && (
              <div
                  className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[1000]"
              >
                {/* 弹窗内容：图片 */}
                <img
                    src={ExpandSpaceSuccess} // 替换为你的图片 URL
                    alt="弹窗背景"
                    className="w-[300px] h-[310px] rounded-lg cursor-pointer"
                    onClick={onClose}
                />
              </div>
          )}

        </div>
        {JsBridge.isInApp() ? "" : <DowloadAppBanner/>}
        <WeChatMask
            isVisible={showWeixinMask}
            closeMask={() => setShowWeixinMask(false)}
            maskType={1}
        />
      </div>
  )
}

export default React.memo(ExpandSpace)

