import {
  AudioRecord,
  MediaItem,
  PlayDataSource,
} from '../../typings/play_data_source';
import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export interface AudioPlayerProps {
  onPlayPause?: (isPlay: boolean) => void; // 播放/暂停回调
  onPlayNext?: (index: number) => void;
  onListPlayEnd?: (index: number) => void;
  playNextInterval?: number;
}

export interface PlayData {
  playlist: PlayDataSource[];
  index: number;
  sentenceIndex: number;
  playRecord: boolean;
  autoPlay: boolean;
  autoPlayNext: boolean;
}

export interface AudioPlayerHandle {
  playPause: () => void;
  pause: () => void;
  play: () => void;
  playNext: () => void;
  playPre: () => void;
  playIndex: (index: number) => void;
  setDataSource: (playData: PlayData) => void;
  setPlayRecord: (isPlayRecord: boolean) => void;
  setAutoPlayNext: (autoPlayNext: boolean) => void;
  isPaused: () => boolean;
  getPlayData: () => PlayData;
}

const MyAudioPlayer = React.forwardRef<AudioPlayerHandle, AudioPlayerProps>(
  (
    {
      onPlayPause,
      onPlayNext,
      onListPlayEnd,
      playNextInterval,
    }: AudioPlayerProps,
    ref: Ref<AudioPlayerHandle>
  ) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [playData, setPlayData] = useState<PlayData>({
      playlist: [],
      index: 0,
      sentenceIndex: 0,
      playRecord: true,
      autoPlay: false,
      autoPlayNext: true,
    });
    const audioRef = useRef<HTMLAudioElement>(null);

    const gEndTime = useRef<number>();
    const playDataRef = useRef<PlayData>(playData);
    const timeoutIdRef = useRef<any>();

    // let timeoutId: any;

    const handleAudioEnded = () => {
      if (!audioRef.current) {
        return;
      }

      if (onPlayPause) {
        onPlayPause(false);
      }

      if (
        !playDataRef.current?.playlist ||
        !playDataRef.current.playlist[playData.index]
      ) {
        return;
      }

      setIsPlaying(false);

      if (
        playDataRef.current.playRecord &&
        playDataRef.current.playlist[playDataRef.current.index].audioRecords &&
        playDataRef.current.playlist[playDataRef.current.index].audioRecords
          .length > 0
      ) {
        //该页最后一条录音
        if (
          playDataRef.current.sentenceIndex >=
          playDataRef.current.playlist[playDataRef.current.index].audioRecords
            .length -
            1
        ) {
          playDataRef.current.sentenceIndex = 0; //确保播放结束后，再次点击该页的播放按钮，会从第一条录音开始播放
          // if(playDataRef.current.index>=playDataRef.current.playlist.length-1){//最后一页
          //     return;
          // }
          if (playDataRef.current.autoPlayNext) {
            if (playNextInterval) {
              handleDelayedPlayNext();
            } else {
              playNext();
            }
          }
        } else {
          playNextSentence();
        }
      } else {
        //该页最后一条音频
        if (
          playDataRef.current.playlist[playDataRef.current.index].mediaList &&
          playDataRef.current.sentenceIndex >=
            playDataRef.current.playlist[playDataRef.current.index].mediaList
              .length -
              1
        ) {
          playDataRef.current.sentenceIndex = 0; //确保播放结束后，再次点击该页的播放按钮，会从第一条音频开始播放
          // if (playDataRef.current.index >= playDataRef.current.playlist.length - 1) {//最后一页
          //     return;
          // }
          if (playDataRef.current.autoPlayNext) {
            if (playNextInterval) {
              handleDelayedPlayNext();
            } else {
              playNext();
            }
          }
        } else {
          playNextSentence();
        }
      }
    };

    const handleDelayedPlayNext = (interval?: number) => {
      // 清除之前的定时器
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        // console.log("clearTimeout",timeoutIdRef.current);
      }
      // console.log("handleDelayedPlayNext 000:",timeoutIdRef.current);
      // 设置新的定时器
      timeoutIdRef.current = setTimeout(() => {
        // console.log("setTimeout 22",timeoutIdRef.current)
        if (playDataRef.current.autoPlayNext) {
          playNext();
        }
      }, interval ?? playNextInterval ?? 500);
      // console.log(`handleDelayedPlayNext setTimeout 11:${timeoutIdRef.current} ${new Error().stack}`);
    };

    const timeupdate = () => {
      // console.log(`playAudio timeupdate currentTime:${audioRef.current!.currentTime} gEndTime:${gEndTime.current}`);
      if (
        audioRef.current &&
        gEndTime.current &&
        audioRef.current!.currentTime >= gEndTime.current
      ) {
        if (audioRef.current!.currentTime < audioRef.current.duration) {
          pauseAudio();
          handleAudioEnded();
        }
      }
    };

    function initAudioListener() {
      let myAudio = audioRef.current!;
      myAudio.removeEventListener('ended', handleAudioEnded);
      myAudio.removeEventListener('timeupdate', timeupdate);
      myAudio.addEventListener('ended', handleAudioEnded);
      myAudio.addEventListener('timeupdate', timeupdate);
    }

    function playAudio() {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        // console.log("clearTimeout",timeoutIdRef.current);
      }
      // console.log(`playAudio:${new Error().stack}`);
      // console.log('playAudio:', audioRef.current);
      if (
        !audioRef.current ||
        !playData.playlist ||
        !playData.playlist[playData.index]
      ) {
        return;
      }
      let url: string | undefined;
      let startTime: number | undefined;
      let endTime: number | undefined;
      let dataSource = playData.playlist[playData.index];
      if (
        playData.playRecord &&
        dataSource.audioRecords &&
        dataSource.audioRecords.length > 0
      ) {
        // console.log(`playData.playRecords:${dataSource.audioRecords.length} playData.sentenceIndex:${playData.sentenceIndex}`);
        // console.log(`playData.playRecord:${playData.playRecord} playData.index:${playData.index}`);
        let audioRecord =
          dataSource.audioRecords[
            playData.sentenceIndex >= dataSource.audioRecords.length
              ? 0
              : playData.sentenceIndex
          ];
        url = audioRecord.mediaUrl;
      } else if (dataSource.mediaList && dataSource.mediaList.length > 0) {
        // console.log(`dataSource.mediaList:${dataSource.mediaList} playData.index:${playData.index}`);
        let mediaItem =
          dataSource.mediaList[
            playData.sentenceIndex >= dataSource.mediaList.length
              ? 0
              : playData.sentenceIndex
          ];
        url = mediaItem.mediaUrl;
        startTime = mediaItem.startSec;
        endTime = mediaItem.endSec;
      }

      let myAudio = audioRef.current;
      gEndTime.current = endTime;
      // console.log(`playAudio timeupdate 222222 currentTime:${audioRef.current!.currentTime} gEndTime:${gEndTime.current}`);

      if (!url) {
        // console.log('playAudio 00:', url,startTime,endTime);
        pauseAudio();
        //空页面停留2s
        if (playDataRef.current.autoPlayNext) {
          handleDelayedPlayNext(2000);
        }
        return;
      }
      // console.log('playAudio 11:', url,startTime,endTime);

      let shallReset: boolean = false;
      if (url !== myAudio.src) {
        shallReset = true;
      } else {
        if (startTime && myAudio.currentTime < startTime) {
          shallReset = true;
        } else if (endTime && myAudio.currentTime >= endTime) {
          shallReset = true;
        }
      }

      myAudio.muted = false;
      myAudio.autoplay = true;
      if (shallReset) {
        myAudio.src = url;
        // console.log('myAudio.src:', myAudio.src,startTime,endTime);
        myAudio.load();
        if (startTime !== undefined && startTime != null) {
          myAudio.currentTime = startTime;
        }
        myAudio.play().catch((reason) => {
          console.error(reason);
        });
      } else {
        myAudio.play().catch((reason) => {
          console.error(reason);
        });
      }
      if (onPlayPause) {
        onPlayPause(true);
      }

      // setHasPlay(true);
    }

    function pauseAudio() {
      if (audioRef.current) {
        // console.log(new Error().stack);
        audioRef.current.pause();
        if (onPlayPause) {
          onPlayPause(false);
        }
      }
    }

    const playPause = () => {
      if (!audioRef.current) {
        return;
      }
      let myAudio = audioRef.current;
      if (myAudio.paused) {
        playAudio();
        setIsPlaying(true);
      } else {
        pauseAudio();
        setIsPlaying(false);
      }
    };

    const play = () => {
      if (!audioRef.current) {
        return;
      }
      playAudio();
    };

    const pause = () => {
      if (!audioRef.current) {
        return;
      }
      pauseAudio();
    };

    const playPre = () => {
      if (!playData.playlist) {
        return;
      }
      let newIndex = playData.index - 1;
      if (newIndex < 0) {
        return;
      }
      playData.index = newIndex;
      playData.sentenceIndex = 0;
      playAudio();
    };

    const playNext = () => {
      if (!playData.playlist) {
        return;
      }

      let newIndex = playData.index + 1;
      if (newIndex >= playData.playlist.length) {
        if (onListPlayEnd) {
          onListPlayEnd(playData.index);
        }
        return;
      }
      if (onPlayNext) {
        onPlayNext(newIndex);
      }
      playData.index = newIndex;
      playData.sentenceIndex = 0;
      playAudio();
    };

    const playNextSentence = () => {
      if (!playData.playlist) {
        return;
      }

      let newSentenceIndex = playData.sentenceIndex + 1;
      if (
        playData.playRecord &&
        playData.playlist[playData.index].audioRecords.length > 0
      ) {
        if (
          !playData.playlist[playData.index].audioRecords ||
          newSentenceIndex >=
            playData.playlist[playData.index].audioRecords.length
        ) {
          newSentenceIndex = 0;
          playData.sentenceIndex = newSentenceIndex;
          return;
        }
      } else {
        if (
          !playData.playlist[playData.index].mediaList ||
          newSentenceIndex >= playData.playlist[playData.index].mediaList.length
        ) {
          newSentenceIndex = 0;
          playData.sentenceIndex = newSentenceIndex;
          return;
        }
      }
      playData.sentenceIndex = newSentenceIndex;
      playAudio();
    };

    const playIndex = (index: number) => {
      if (!playData.playlist || !audioRef.current) {
        return;
      }
      if (index >= 0 && index < playData.playlist.length) {
        // console.log(`MyAudioPlayer playIndex:${index}`);
        if (playData.index !== index) {
          playData.index = index;
          playData.sentenceIndex = 0;
          playAudio();
        } else if (audioRef.current.paused) {
          playAudio();
        }
      }
    };

    const setPlayRecord = (isPlayRecord: boolean) => {
      playData.playRecord = isPlayRecord ?? true;
      // console.log(`setPlayRecord:${isPlayRecord}`);
      playAudio();
    };

    const setAutoPlayNext = (autoPlayNext: boolean) => {
      playData.autoPlayNext = autoPlayNext ?? true;
      // console.log(`setPlayRecord:${isPlayRecord}`);
      // playAudio();
    };

    const isPaused = () => {
      if (audioRef.current) {
        return audioRef.current.paused;
      } else {
        return !isPlaying;
      }
    };

    const setDataSource = (data: PlayData) => {
      if (!data) {
        return;
      }
      setPlayData(data);
    };

    const getPlayData = () => {
      return playData;
    };

    useImperativeHandle(ref, () => ({
      playPause,
      play,
      pause,
      playNext,
      playPre,
      playIndex,
      setDataSource,
      isPaused,
      setPlayRecord,
      setAutoPlayNext,
      getPlayData,
    }));

    useEffect(() => {
      playDataRef.current = playData;
      // 清除之前的定时器
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      if (playData.playlist.length <= 0) {
        return;
      }

      if (audioRef.current) {
        audioRef.current.src = '';
        pauseAudio();
        initAudioListener();
        if (playData.autoPlay) {
          playAudio();
        }
      }
    }, [playData]);

    useEffect(() => {
      // 调用APP关闭 webview 调用该方法
      window.onCloseWebView = () => {
        pauseAudio();
      };

      return () => {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }
      };
    }, []);

    return (
      <audio
        id='myAudio'
        ref={audioRef}
        preload='auto'
        style={{ display: 'none' }}
        muted
        playsInline
      />
    );
  }
);

export default MyAudioPlayer;
