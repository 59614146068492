import React, {useEffect, useState} from 'react';
import {getSpaceCapacity, getSpaceGoodsList} from "../../../restful/space_api";
import {SpaceCapacity, SpaceGoodsItem} from "../../../typings/space";
import {formatStorageSize} from "../../../utils/common";

interface StorageInfoProps {
    className?: string; // className 是可选的
    refresh: boolean;
}

function StorageInfo({ className, refresh }: StorageInfoProps) {
    const [spaceCapacity, setSpaceCapacity] = useState<SpaceCapacity | undefined>();
    const [usedPercentage, setUsedPercentage] = useState<number>(0);

    const fetchSpaceCapacity = async () => {
        const response = await getSpaceCapacity();
        if (response?.data?.data) {
            const capacity = response.data.data;
            console.log('space capacity:', capacity);
            setSpaceCapacity(capacity);
            setUsedPercentage(Math.min(capacity.used / capacity.total, 1) * 100);
        }
    };

    useEffect(() => {
        fetchSpaceCapacity();
    }, []);

    useEffect(() => {
        fetchSpaceCapacity();
    }, [refresh]);

    return (
        <div className={`${className}`}>
            <div className="relative w-full h-[16px] bg-[#d9f4ff] rounded-[4px] mb-[8px]">
                <div
                    className={`absolute top-0 left-0 h-[16px] bg-[#51e0fc] rounded-tl-[4px] rounded-bl-[4px] ${(usedPercentage >= 100) ? "rounded-tr-[4px] rounded-br-[4px]" : "rounded-tr-[2px] rounded-br-[2px]"}`}
                    style={{ width: `${usedPercentage}%` }}
                ></div>
            </div>
            <div className="flex justify-between text-sm text-gray-500">
                <div className="flex items-center">
                    <div className="bg-[#d9f4ff] rounded-[2px] w-[12px] h-[12px] mr-[4px]"/>
                    <span>总容量{formatStorageSize(spaceCapacity?.total ?? 0)}</span>
                </div>
                <div className="flex items-center">
                    <div className="bg-[#51e0fc] rounded-[2px] w-[12px] h-[12px] mr-[4px]"/>
                    <span>已使用{formatStorageSize(spaceCapacity?.used ?? 0)}</span>
                </div>
            </div>
        </div>
    );
};

export default StorageInfo;