import BackIcon from '@/images/h5-self-service/back.webp';
import { ReactNode } from 'react';

interface HeaderProps {
  onClickBack?: () => void;
  title?: string;
  leftBtn?: string | ReactNode;
  rightBtn?: string | ReactNode;
  isShowBack?: boolean;
  isNeedFixedTop?: boolean;
  headerBgColor?: string;
  className?: string;
}

function Header({
  title = '',
  onClickBack,
  leftBtn,
  rightBtn,
  isShowBack = true,
  isNeedFixedTop = false,
  headerBgColor = '',
  className,
}: HeaderProps) {
  return (
    <>
      <div
        className={`${
          isNeedFixedTop ? 'fixed top-0 left-0 w-full' : ''
        } flex justify-between items-center p-[18px] z-[999] ${className}`}
        style={{ background: headerBgColor }}
      >
        <div className='w-[66px] h-[36px]'>
          {isShowBack ? (
            leftBtn != null ? (
              leftBtn
            ) : (
              <img
                onClick={onClickBack}
                src={BackIcon}
                alt=''
                className='w-[36px] h-[36px]'
              />
            )
          ) : (
            <></>
          )}
        </div>
        <div
          className={`text-[#493414] text-[18px] font-semibold text-center truncate flex-1`}
        >
          {title}
        </div>
        <div className='w-[66px] h-[36px] flex items-center text-[14px] justify-end'>
          {rightBtn}
        </div>
      </div>
      {isNeedFixedTop ? <div className='min-h-[72px]'></div> : null}
    </>
  );
}

export default Header;
