import { requestGet } from './request';

// 获取录音分享数据
// export function getRecordedShareData(r: string) {
//   return axiosInstance.get('/v1/share/uar?r=' + r);
// }

export function getRecordedShareData<T>({ r }: Record<string, any>): Promise<{
  success: boolean;
  data?: T;
  msg?: string;
  code?: number;
}> {
  const params = {
    r: r,
  };

  return requestGet<T>('/v1/share/uar', params);
}
