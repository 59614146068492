import React, {useEffect, useState} from 'react';
import Header from '../../components/h5/Header';
import StorageInfo from './components/StorageInfo';
import PurchaseOptions from './components/PurchaseOptions';
import ShareCapacityInfo from './components/ShareCapacityInfo';
import VConsole from "vconsole";
import {JsBridge} from "../../utils/JsBridge";
import BgImg from '@/images/space_upgrade/bg.webp';
import BackIcon from "@/images/space_upgrade/back.webp";
import MySpaceImg from '@/images/space_upgrade/my_space.webp';
import {useLocation, useNavigate} from "react-router-dom";

const SpaceUpgrade: React.FC = () => {
    const [headerMaskOpacity, setHeaderMaskOpacity] = useState(0);

    useEffect(() => {
        JsBridge.hideClientNavbar(
            JSON.stringify({ visible: false, statusBarColor: '#bccdf2' })
        );
        JsBridge.setClickBackCallback(() => {
           onClose()
        })

        const handleScroll = () => {
            const scrollTop = window.scrollY // 当前滚动位置
            console.log(scrollTop)
            const maxScroll = 30 // 滚动范围的最大值
            const newOpacity = Math.min(Math.max(scrollTop / maxScroll, 0), 1) // 计算透明度
            console.log(newOpacity)
            setHeaderMaskOpacity(newOpacity) // 设置透明度
        }

        window.addEventListener('scroll', handleScroll);
        let vConsole: VConsole | null = null;
        if (window.location.host === 'testvq.deerbook.cn' || window.location.host.startsWith("192.168.31")) {
            vConsole = new VConsole()
        }
        return () => {
            vConsole?.destroy()
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const hasPreviousPage = location.key !== "default"; // "default" 表示没有历史记录

    function onClose() {
        if (hasPreviousPage) {
            navigate(-1); // 返回上一页
        } else {
            JsBridge.closeWebview(JSON.stringify({ sourceFrom: 'h5' }));
        }
    }

    function backBtn() {
        return <img
            onClick={onClose}
            src={BackIcon}
            alt=''
            className='w-[36px] h-[36px]' />
    }

    return (
        <div className="relative min-h-screen">
            <div className="fixed top-0 left-0 w-full h-full bg-[#f6f6f6] -z-10"></div>
            <img
                src={BgImg}
                alt="背景图"
                className="w-full h-auto"
            />
            <div className="absolute top-[145px] left-0 w-full h-[667px] bg-gradient-to-b from-[#E2E7FF00] to-[#EBECF2]" />
            <div className="fixed w-full top-0 left-[0px] z-[999] pl-0 pr-0 bg-[#bccdf2] bg-opacity-50" style={{ backgroundColor: `rgb(188, 205, 242, ${headerMaskOpacity})` }}>
                <Header title="空间扩容" leftBtn={backBtn()}/>
            </div>

            <div className="absolute mt-[72px] top-0 left-0 w-full h-full pl-[15px] pr-[15px]">
                <div className="relative mt-[13px]">
                    <div className="bg-white bg-clip-padding rounded-[15px] p-[16px] border-[2px] border-[#7BA2FF14]">
                        <StorageInfo className="mt-[32px]" refresh={false}/>
                    </div>
                    <img src={MySpaceImg} className="w-[117px] h-[41px] absolute top-[-5px] left-[-3px]" />
                </div>
                <div className="mt-[13px] bg-white bg-clip-padding rounded-[15px] p-[16px] pb-[7px] border-[2px] border-[#7BA2FF14]">
                    <PurchaseOptions />
                </div>
                <div className="mt-[13px] bg-white bg-clip-padding rounded-[15px] p-[16px] border-[2px] border-[#7BA2FF14] mb-[158px]">
                    <ShareCapacityInfo/>
                </div>
            </div>
        </div>
    );
};

export default SpaceUpgrade;