import React, {useEffect, useRef, useState} from 'react';
import {SpaceGoodsItem} from "../../../typings/space";

interface PurchaseOptionCardProps {
    spaceGoodsItem: SpaceGoodsItem;
    onClickBack?: () => void;
    className?: string;
}

function PurchaseOptionCard({spaceGoodsItem, onClickBack, className} : PurchaseOptionCardProps) {
    const thirdDivRef = useRef<HTMLDivElement>(null); // 获取第三个子div
    const [dynamicWidth, setDynamicWidth] = useState<number>(42); // 默认宽度

    // 动态获取第三个子div的宽度
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (thirdDivRef.current) {
                const width = thirdDivRef.current.offsetWidth;
                setDynamicWidth(width); // 更新宽度
            }
        });

        if (thirdDivRef.current) {
            resizeObserver.observe(thirdDivRef.current); // 监听宽度变化
        }

        return () => resizeObserver.disconnect(); // 清理监听器
    }, []);


    return (
        <div
            className={`flex items-center justify-between ${className}`}>
            {/* 左侧图标 + 文本 */}
            <div className="flex items-center ml-[4px]">
                <div className="relative">
                    <div className="h-[41px] border-[0.66px] border-[#8EE9FF] rounded-[5.29px] shadow-[1.32px_0px_0px_#8FE8FF]"
                         style={{ width: `${dynamicWidth}px` }}/>
                    <div className="h-[4px]"/>
                    <div
                        ref={thirdDivRef}
                        className="absolute top-[4px] left-[-4px] min-w-[42px] h-[41px] px-[4px] flex items-center justify-center rounded-[5.29px] bg-gradient-to-b from-[#0CD1E7] to-[#28BEFF]">
                        <span className="text-white text-[21px] font-baloo_bhai font-sw">
                            {Math.floor(spaceGoodsItem.capacity / 1024)}
                            <span className="text-[13px]">G</span>
                        </span>
                    </div>
                </div>
                {/* 文本内容 */}
                <div className="ml-[18px]">
                    <p className="text-[#232322] font-medium text-[16px]">{spaceGoodsItem.name}</p>
                    <p className="text-[#FF4D00] font-semibold text-[18px]">
                        {spaceGoodsItem.money/100}￥<span className="font-normal text-[12px]">/年</span>
                    </p>
                </div>
            </div>

            {/* 立即购买按钮 */}
            <button className="bg-[#FF7700] text-white text-[12px] font-semibold px-[13px] py-[9px] rounded-full" onClick={onClickBack}>
                立即购买
            </button>
        </div>
    );
};

export default PurchaseOptionCard;