import {Toast} from "antd-mobile";
import {TimeModel} from "../typings/time_model";

export function getUrlParam(param: string) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const _param = params.get(param) || '';

  return _param;
}

export function objectFlat<T>(allCheckedList: { [key: string]: T[] }): T[] {
  let flatList: T[] = [];
  // 遍历对象，将值取出来，合并成一个数组
  for (const key in allCheckedList) {
    if (Object.prototype.hasOwnProperty.call(allCheckedList, key)) {
      const element = allCheckedList[key];
      flatList = flatList.concat(element);
    }
  }
  return flatList;
}

export function isNotEmptyStr(s:any) {
  if (typeof s == 'string' && s.length > 0) {
    return true
  }
  return false
}

export function isEmptyStr(s:any) {
  return !isNotEmptyStr(s);
}

function copyToClipboardCommand(shareContent:any):boolean{
  const textArea = document.createElement('textarea');
  document.body.appendChild(textArea);
  textArea.textContent = shareContent;
  textArea.select();
  try {
    const isOk = document.execCommand('copy');
    if(isOk){
      Toast.show({content:`${shareContent}`});
      return true;
    }else{
      Toast.show({content:`复制失败，请尝试手动复制`});
      return false;
    }
  } catch (err) {
    Toast.show({content:`复制失败，请尝试手动复制`});
    return false;
  }
  finally {
    document.body.removeChild(textArea);
  }
}

export async function copyToClipboard(shareContent:any):Promise<boolean> {
  if(window.isSecureContext) {
    try {
      await navigator.clipboard.writeText(shareContent);
      Toast.show({content: `${shareContent}`});
      return true;
    } catch (err) {
      return copyToClipboardCommand(shareContent);;
    }
  }else{
    return copyToClipboardCommand(shareContent);
  }
}

export function formatAges(str: string) {
  if(!str.includes(",")){
    return str.trim();
  }
  // 分割字符串为数组
  const numbers = str.split(',').map(Number);

  // 找到最小值和最大值
  const min = Math.min(...numbers);
  const max = Math.max(...numbers);

  // 构建结果字符串
  return `${min}~${max}`;
}

export async function share({title,text,url}:Record<string, any>):Promise<boolean> {
  try {
    await navigator.share({
      title: title,
      text: text,
      url: url,
    });
    return true;
  } catch (error) {
    return false;
  }
}

export function timeDifference(expireTimeString?:string):TimeModel|undefined{

  if(!expireTimeString){
    return undefined;
  }

  let timeModel:TimeModel|undefined;
  const expireTime:Date = new Date(expireTimeString.replace(' ', 'T') + 'Z');
  const now:Date = new Date();
  const difference:number = expireTime.getTime() - now.getTime();

  if (difference > 0) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    timeModel = { days, hours, minutes, seconds };
  }
  return timeModel;
}

// utils/dateUtils.ts
export const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString) return ""; // 如果日期不存在，返回空字符串

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 月份从 0 开始，需要加 1
  const day = date.getDate();

  return `${year}.${month}.${day}`;
};

export function formatStorageSize(sizeInMB: number): string {
  if (sizeInMB < 0) {
    return '0 MB';
  }

  const formatNumber = new Intl.NumberFormat('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: false,
  }).format;

  if (sizeInMB >= 1024 * 1024) {
    return `${formatNumber(sizeInMB / (1024 * 1024))} TB`;
  }
  if (sizeInMB >= 1024) {
    return `${formatNumber(sizeInMB / 1024)} GB`;
  }
  return `${formatNumber(sizeInMB)} MB`;
}
