import {axiosInstance, requestGet, requestPost} from "./request";
import {DirectoryBasicModel} from "../typings/directory_basic_model";

export function getSpaceGoodsList() {
    return axiosInstance.get("/v1/goods/spaces");
}

export function getSpaceCapacity() {
    return axiosInstance.get("/v1/user/myCapacity");
}

export function vipGetFreeSpace():Promise<{success: boolean, data?: any, msg?: string, code?: number}> {
    return requestPost<any>('/v1/activity/vipGetFreeSpace');
}