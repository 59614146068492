import React from 'react';
import ShareTipsImg from '@/images/space_upgrade/share_tips.webp';

const ShareCapacityInfo: React.FC = () => {
    return (
        <div>
            <h2 className="text-[18px] text-[#090909] font-bold ml-[2px]">分享资源得容量</h2>
            <p className="bg-[#F0F6FF] rounded-[11px] p-[9px] text-[13px] text-black font-normal mt-[9px]">
                分享资源给他人，他人<span className="text-[#FFA000]">转存1次</span>，可<span
                className="text-[#FFA000]">得300M</span>容量空间，以此叠加;
                如是<span className="text-[#FFA000]">发布者</span>，另<span className="text-[#FFA000]">加300M</span>。
                <br/>
                1. 对于同一个资源，一个人获取空间的上限为 10 G；
                <br/>
                2. 自己转存自己的课程无法获得额外的转存奖励；
                <br/>
                3. 他人转存一个课程，只能给分享者或者发布者贡献一次奖励，删除后再次转存，无法重复获得奖励。
            </p>
            <div className="px-[13px] flex justify-center mt-[16px]">
                <img src={ShareTipsImg} className="w-full max-w-[500px] h-auto"/>
            </div>
        </div>
    );
};

export default ShareCapacityInfo;