import React, {useEffect, useState} from 'react';
import {getSpaceGoodsList} from '../../../restful/space_api';
import {SpaceGoodsItem} from '../../../typings/space';
import {JsBridge} from '../../../utils/JsBridge';
import PurchaseOptionCard from './PurchaseOptionCard';
import { UpOutline, DownOutline } from 'antd-mobile-icons';

const PurchaseOptions: React.FC = () => {
    const [spaceGoodsList, setSpaceGoodsList] = useState<SpaceGoodsItem[]>([]);
    const [isExpanded, setIsExpanded] = useState(false); // 添加状态来控制展开和收起

    useEffect(() => {
        const fetchSpaceGoods = async () => {
            const response = await getSpaceGoodsList();
            if (response?.data?.data) {
                const list = response.data.data;
                console.log('space goods:', list);
                setSpaceGoodsList(list);
            }
        };
        fetchSpaceGoods();
    }, []);

    const buySpace = (spaceGoodsItem: SpaceGoodsItem) => {
        console.log('buy space:', spaceGoodsItem);
        const data = JSON.stringify({goodsId: spaceGoodsItem.id});
        JsBridge.buyVip(data);
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    if (spaceGoodsList.length === 0) {
        return null;
    }

    return (
        <div>
            <h2 className="text-[18px] text-[#090909] font-bold ml-[2px]">购买容量</h2>
            {spaceGoodsList.slice(0, isExpanded ? spaceGoodsList.length : 3).map((item, index) => (
                <div key={index}>
                    {index > 0 && <div className="h-[0.5px] bg-[#e8e8e8]"/>}
                    <PurchaseOptionCard
                        className={`${index === 0 ? 'mt-[19px]' : 'mt-[23px]'} mb-[23px]`}
                        spaceGoodsItem={item}
                        onClickBack={() => buySpace(item)}
                    />
                </div>
            ))}
            {spaceGoodsList.length > 3 && (
                <div className="text-[#333333] font-semibold w-full flex justify-center items-center mb-[8px]" onClick={toggleExpand}>
                    {isExpanded ? (
                        <>
                            <span>收起</span>
                            <UpOutline style={{ marginLeft: '6px' }} />
                        </>
                    ) : (
                        <>
                            <span>展开更多</span>
                            <DownOutline style={{ marginLeft: '6px' }} />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default PurchaseOptions;
